.logo {
    width: 230px;
    height: 100px;
}

.form-box {
    height: 85%;
}

.full-height {
    height: 100%;
}

.container {
    max-width: 100%;
}

.banner {
    background-color: #f7fafc;
}

.text-center {
    text-align: center;
}

.MuiPaper-root {
    color: #263238;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #fff;
}

.MuiPaper-rounded {
    border-radius: 4px;
}

.MuiPaper-elevation0 {
    box-shadow: none;
}

.MuiPaper-elevation1 {
    box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 2px 0 rgba(63, 63, 68, 0.15);
}

.MuiSvgIcon-root {
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
}

.MuiSvgIcon-fontSizeInherit {
    font-size: inherit;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    width: 100%;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    width: 100%;
    height: 100%;
}

.MuiTypography-root {
    margin: 0;
}

.MuiTypography-body2 {
    font-size: 0.875rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
}

.MuiTypography-h2 {
    font-size: 29px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: -0.24px;
}

.MuiTypography-h4 {
    font-size: 16px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: -0.24px;
}

.MuiTypography-gutterBottom {
    margin-bottom: 0.35em;
}

.MuiTypography-colorTextPrimary {
    color: #263238;
}

.MuiTypography-colorTextSecondary {
    color: #546e7a;
}

.MuiLink-underlineHover {
    text-decoration: none;
}

.MuiLink-underlineHover:hover {
    text-decoration: underline;
}

.MuiTouchRipple-root {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    overflow: hidden;
    position: absolute;
    border-radius: inherit;
    pointer-events: none;
}

@-webkit-keyframes MuiTouchRipple-keyframes-enter {
    0% {
        opacity: 0.1;
        transform: scale(0);
    }
    100% {
        opacity: 0.3;
        transform: scale(1);
    }
}

@-webkit-keyframes MuiTouchRipple-keyframes-exit {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@-webkit-keyframes MuiTouchRipple-keyframes-pulsate {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(0.92);
    }
    100% {
        transform: scale(1);
    }
}

.MuiButtonBase-root {
    color: inherit;
    border: 0;
    cursor: pointer;
    margin: 0;
    display: inline-flex;
    outline: 0;
    padding: 0;
    position: relative;
    align-items: center;
    user-select: none;
    border-radius: 0;
    vertical-align: middle;
    -moz-appearance: none;
    justify-content: center;
    text-decoration: none;
    background-color: transparent;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}

.MuiButtonBase-root::-moz-focus-inner {
    border-style: none;
}

@media print {
    .MuiButtonBase-root {
        -webkit-print-color-adjust: exact;
    }
}

.MuiButton-root {
    color: #263238;
    padding: 6px 16px;
    font-size: 0.875rem;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
}

.MuiButton-root:hover {
    text-decoration: none;
    background-color: rgba(38, 50, 56, 0.04);
}

@media (hover: none) {
    .MuiButton-root:hover {
        background-color: transparent;
    }
}

.MuiButton-label {
    width: 100%;
    display: inherit;
    align-items: inherit;
    justify-content: inherit;
}

.MuiButton-contained {
    color: rgba(0, 0, 0, 0.87);
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.31), 0 2px 2px -2px rgba(0, 0, 0, 0.25);
    background-color: #e0e0e0;
}

.MuiButton-contained:hover {
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.31), 0 3px 4px -2px rgba(0, 0, 0, 0.25);
    background-color: #d5d5d5;
}

.MuiButton-contained:active {
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.31), 0 5px 8px -2px rgba(0, 0, 0, 0.25);
}

@media (hover: none) {
    .MuiButton-contained:hover {
        box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.31), 0 2px 2px -2px rgba(0, 0, 0, 0.25);
        background-color: #e0e0e0;
    }
}

.MuiButton-containedSecondary {
    color: #fff;
    background-color: #5850EC;
}

.MuiButton-containedSecondary:hover {
    background-color: rgb(61, 56, 165);
}

@media (hover: none) {
    .MuiButton-containedSecondary:hover {
        background-color: #5850EC;
    }
}

.MuiButton-containedSizeLarge {
    padding: 8px 22px;
    font-size: 0.9375rem;
}

.MuiButton-fullWidth {
    width: 100%;
}

.MuiButton-iconSizeSmall > *:first-child {
    font-size: 18px;
}

.MuiButton-iconSizeMedium > *:first-child {
    font-size: 20px;
}

.MuiButton-iconSizeLarge > *:first-child {
    font-size: 22px;
}

.MuiDivider-root {
    border: none;
    height: 1px;
    margin: 0;
    flex-shrink: 0;
    background-color: rgba(0, 0, 0, 0.12);
}

.MuiInputBase-root {
    color: #263238;
    cursor: text;
    display: inline-flex;
    position: relative;
    font-size: 1rem;
    box-sizing: border-box;
    align-items: center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.1876em;
    letter-spacing: 0.00938em;
}

.MuiInputBase-fullWidth {
    width: 100%;
}

.MuiInputBase-input {
    font: inherit;
    color: currentColor;
    width: 100%;
    border: 0;
    height: 1.1876em;
    margin: 0;
    display: block;
    padding: 6px 0 7px;
    min-width: 0;
    background: none;
    box-sizing: content-box;
    animation-name: mui-auto-fill-cancel;
    letter-spacing: inherit;
    animation-duration: 10ms;
    -webkit-tap-highlight-color: transparent;
}

.MuiInputBase-input::-webkit-input-placeholder {
    color: currentColor;
    opacity: 0.42;
    transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.MuiInputBase-input::-moz-placeholder {
    color: currentColor;
    opacity: 0.42;
    transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.MuiInputBase-input:-ms-input-placeholder {
    color: currentColor;
    opacity: 0.42;
    transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.MuiInputBase-input::-ms-input-placeholder {
    color: currentColor;
    opacity: 0.42;
    transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.MuiInputBase-input:focus {
    outline: 0;
}

.MuiInputBase-input:invalid {
    box-shadow: none;
}

.MuiInputBase-input::-webkit-search-decoration {
    -webkit-appearance: none;
}

.MuiInputBase-input:-webkit-autofill {
    animation-name: mui-auto-fill;
    animation-duration: 5000s;
}

.MuiInputBase-input::placeholder {
    color: #546e7a;
    opacity: 1;
}

label[data-shrink=false] + .MuiInputBase-formControl .MuiInputBase-input::-webkit-input-placeholder {
    opacity: 0 !important;
}

label[data-shrink=false] + .MuiInputBase-formControl .MuiInputBase-input::-moz-placeholder {
    opacity: 0 !important;
}

label[data-shrink=false] + .MuiInputBase-formControl .MuiInputBase-input:-ms-input-placeholder {
    opacity: 0 !important;
}

label[data-shrink=false] + .MuiInputBase-formControl .MuiInputBase-input::-ms-input-placeholder {
    opacity: 0 !important;
}

label[data-shrink=false] + .MuiInputBase-formControl .MuiInputBase-input:focus::-webkit-input-placeholder {
    opacity: 0.42;
}

label[data-shrink=false] + .MuiInputBase-formControl .MuiInputBase-input:focus::-moz-placeholder {
    opacity: 0.42;
}

label[data-shrink=false] + .MuiInputBase-formControl .MuiInputBase-input:focus:-ms-input-placeholder {
    opacity: 0.42;
}

label[data-shrink=false] + .MuiInputBase-formControl .MuiInputBase-input:focus::-ms-input-placeholder {
    opacity: 0.42;
}

.jss103 {
    top: -5px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    padding: 0 8px;
    overflow: hidden;
    position: absolute;
    border-style: solid;
    border-width: 1px;
    border-radius: inherit;
    pointer-events: none;
}

.jss105 {
    width: auto;
    height: 11px;
    display: block;
    padding: 0;
    font-size: 0.75em;
    max-width: 0.01px;
    text-align: left;
    transition: max-width 50ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    visibility: hidden;
}

.jss105 > span {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
}

.MuiOutlinedInput-root {
    position: relative;
    border-radius: 4px;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #263238;
}

@media (hover: none) {
    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
        border-color: rgba(0, 0, 0, 0.23);
    }
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #3949ab;
    border-width: 2px;
}

.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #f44336;
}

.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.26);
}

.MuiOutlinedInput-colorSecondary.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #5850EC;
}

.MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.23);
}

.MuiOutlinedInput-input {
    padding: 18.5px 14px;
}

.MuiOutlinedInput-input:-webkit-autofill {
    border-radius: inherit;
}

.MuiFormLabel-root {
    color: #546e7a;
    padding: 0;
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.00938em;
}

.MuiInputLabel-root {
    display: block;
    transform-origin: top left;
}

.MuiInputLabel-formControl {
    top: 0;
    left: 0;
    position: absolute;
    transform: translate(0, 24px) scale(1);
}

.MuiInputLabel-animated {
    transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
}

.MuiInputLabel-outlined {
    z-index: 1;
    transform: translate(14px, 20px) scale(1);
    pointer-events: none;
}

.MuiFormControl-root {
    border: 0;
    margin: 0;
    display: inline-flex;
    padding: 0;
    position: relative;
    min-width: 0;
    flex-direction: column;
    vertical-align: top;
}

.MuiFormControl-marginNormal {
    margin-top: 16px;
    margin-bottom: 8px;
}

.MuiFormControl-fullWidth {
    width: 100%;
}

.MuiFormHelperText-root {
    color: #546e7a;
    margin: 0;
    font-size: 0.75rem;
    margin-top: 3px;
    text-align: left;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
}

.MuiFormHelperText-root.Mui-error {
    color: #f44336;
}

.MuiFormHelperText-contained {
    margin-left: 14px;
    margin-right: 14px;
}

.MuiContainer-root {
    width: 100%;
    display: block;
}

@media (min-width: 600px) {
    .MuiContainer-maxWidthSm {
        max-width: 600px;
    }
}

.MuiCard-root {
    overflow: hidden;
}

.MuiCardContent-root {
    padding: 16px;
}

.MuiCardContent-root:last-child {
    padding-bottom: 24px;
}

.MuiAlert-root {
    display: flex;
    padding: 6px 16px;
    font-size: 0.875rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    border-radius: 4px;
    letter-spacing: 0.01071em;
    background-color: transparent;
}

.MuiAlert-standardSuccess .MuiAlert-icon {
    color: #4caf50;
}

.MuiAlert-standardInfo {
    color: rgb(13, 60, 97);
    background-color: rgb(232, 244, 253);
}

.MuiAlert-standardInfo .MuiAlert-icon {
    color: #2196f3;
}

.MuiAlert-standardWarning .MuiAlert-icon {
    color: #ff9800;
}

.MuiAlert-standardError .MuiAlert-icon {
    color: #f44336;
}

.MuiAlert-outlinedSuccess .MuiAlert-icon {
    color: #4caf50;
}

.MuiAlert-outlinedInfo .MuiAlert-icon {
    color: #2196f3;
}

.MuiAlert-outlinedWarning .MuiAlert-icon {
    color: #ff9800;
}

.MuiAlert-outlinedError .MuiAlert-icon {
    color: #f44336;
}

.MuiAlert-icon {
    display: flex;
    opacity: 0.9;
    padding: 7px 0;
    font-size: 22px;
    margin-right: 12px;
}

.jss85 {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    background-color: #f4f6f8;
}

.jss90 {
    height: 85%;
    display: flex;
    padding: 32px;
    min-height: 400px;
    flex-direction: column;
}

.jss91 > img {
    width: auto;
    max-height: 100%;
}
